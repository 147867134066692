import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'

//globals
import InlineSvg from 'vue-inline-svg'

//state
import {store} from './store'

//routes
//import VueGtag from "vue-gtag-next"
import router from './router'

//templates
import Splash from "@/components/pages/Splash"
import Generic from "@/components/pages/Generic"

//GLOBAL CONFIG
/*
let craftApiPreviewToken = ''
if (window.siteConfig?.xCraftLivePreview) {
    craftApiPreviewToken = '?tokenParam=' + window.siteConfig?.xCraftLivePreview + '&token=' + window.siteConfig?.xCraftLivePreviewToken
}
 */
window.config = {
    metaTitleAddon: '',
    loadedOnce: true, //skip transition for now
    routerInTransition: false,
    apiroot: (window.localMode) ? '/api/' : '/api/',
    //apiext: (window.localMode) ? '.json' : craftApiPreviewToken,
    apiext: (window.localMode) ? '.json' : '.json',
    localMode: (window.localMode),
    json: {
        global: null
    },
}
axios.get((`${window.config.apiroot}global${window.config.apiext}`))
    .then((res) => {
        //GLOBAL CONFIG
        window.config.json.global = res.data

        //DYNAMIC ROUTESjson
        for (let i = 0; i < res.data.routes.length; i++) {
            let obj = res.data.routes[i]
            let newobj = {
                path: obj.path,
                meta: {
                    title: obj.title,
                    type: obj.type
                },
            }
            switch (obj.type) {
                case 'home':
                    newobj.component = Splash
                    break
                case 'generic':
                    newobj.component = Generic
                    break
            }
            router.addRoute(newobj)
        }


        //page not found
        let newobj = {
            name: 'page-not-found',
            path: "/:catchAll(.*)",
            //component: PageNotFound,
            meta: {
                title: '404'
            }
        }
        router.addRoute(newobj)

        //START APP
        startApp()
    }).catch(() => {
})

function startApp () {
    //LOAD
    const app = createApp(App)
    app.use(router)
    app.use(store)



    //ANALYTICS - in your main.js, below loading your router
    //if (!window.localMode) {
    /*
        app.use(VueGtag, {
            property: {
                id: window.config.json.global.config?.gaTag
            }
        })

     */
    //}

    //global inline svg
    app.component('inline-svg', InlineSvg)

    app.mount('#app')
}
