<template>
    <div id="container" :class="[(revealReady) ? 'opacity1' : 'opacity0', routeName, routeType]">
        <div id="main" :class="[(innerRevealReady) ? 'opacity1' : 'opacity0']">
            <transition name="fade" >
                <router-view :key="$route.fullPath"></router-view>
            </transition>
        </div>
    </div>
</template>

<script>
import gh from '@/helpers/gh'
import router from '@/router'
//import gsap, {Power3, Power2, Power1} from 'gsap'

export default {
    name: 'App',
    components: {
        //TransitionOverlay
    },
    data() {
        return {
            window:window,
            document:document,
            showBlocker:false,
            revealReady:false,
            innerRevealReady:false,
            didInitialReveal:false,
        }
    },
    mounted() {

    },
    computed: {
        routeName() {
            const path = gh.stripSlashesFrontAndBack(router.currentRoute.value.path)
            return `route-${path}`
        },
        routeType() {
            const type = gh.stripSlashesFrontAndBack(router.currentRoute.value.meta.type)
            return `route-type-${type}`
        }
    },
    watch: {
        routeType() {
            if (this.didInitialReveal) {
                this.innerRevealReady = false
                setTimeout(this.revealInnerPageAnimations, 100)
            }
        }
    },
    methods: {

    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
